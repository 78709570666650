import React, {useContext} from 'react';
import './App.css';
import ReactDOM from "react-dom";
import {Button, Checkbox, Container, Dropdown, Form, FormField, Input, List, Radio} from "semantic-ui-react";
import {CookiesProvider, useCookies} from 'react-cookie';
import {setDefaultData, track, Tracking} from './Tracking.js';
import Search from './Search.js';
import Capture from "./Capture";
import Inventory from "./Inventory";

const format = require('format-duration')

const darkMode = "" +
    ":root { filter: invert();} " +
    "img {filter: invert();}" +
    "video {filter: invert();}" +
    "canvas {filter: invert();}";

let cookieOptions = {path: '/', expires: new Date(1427383583165000)};
const SITE_ID = "7853dd11-796d-4733-8c40-d748b95a9d88";

const App = () => {
    return <Tracking.Provider value={track}>
        <Container>
            {window.location.pathname === "/search" ? <Search/> : <CookiesProvider><Login/></CookiesProvider>}
        </Container>
    </Tracking.Provider>
}

const Login = () => {
    const [cookies, setCookie] = useCookies(['cid']);
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("cid")) {
        setCookie('cid', urlParams.get("cid"), cookieOptions);
        track("login", {"cid": urlParams.get("cid")});
        if (window.history.replaceState) {
            window.history.replaceState(null, "", window.location.origin);
        }
        return <meta httpEquiv="refresh" content="0"/>
    }

    let path = window.location.pathname;
    return <Tracking.Provider value={track}>
        <Container>
            {!cookies.cid || cookies.cid === "" ?
                <div>
                    L {track("logged_out", {})}
                    <h1><img src="roamapp-name.png" height="90" alt="ROAMapp" style={{margin: "20px"}}/></h1>
                    <style type="text/css">
                        {":root { background-color: #FFFFFF; }"}
                    </style>
                    <h3>If you are already on the ROAM discord, sign in.</h3>
                    <br/>
                    <a href="https://discord.com/api/oauth2/authorize?client_id=738922845421437008&redirect_uri=https%3A%2F%2Fdiscord.albionroamapp.com%2Fauth%2Fcallback&response_type=code&scope=identify%20guilds"
                       target="_self" title="Sign in with Discord">
                        <img src="log-in-with-discord.svg" alt="Sign in with Discord"/>
                    </a>
                    <br/>
                    <h3>Otherwise, first join the server using this link:</h3>
                    <br/>
                    <a href="https://discord.gg/BwQ5nUK" target="_blank" rel="noopener noreferrer"><img
                        src="join-our-discord.svg" alt="Join ROAM Discord"/></a>
                </div>
                : path === "/" ?
                    <Directions/> :
                    path === "/search" ?
                        <Search/> :
                        path === "/inventory" ?
                            <Inventory/> : ""
            }
        </Container>
    </Tracking.Provider>
}
const portalOptions = [
    {key: "F", text: "Fort Sterling", value: "F"},
    {key: "M", text: "Martlock", value: "M"},
    {key: "T", text: "Thetford", value: "T"},
    {key: "L", text: "Lymhurst", value: "L"},
    {key: "B", text: "Bridgewatch", value: "B"},
];

// Expirations match this regex as it is typed
const hoursMinutesEdit = /^(([0-2]?[0-9])(:([0-9][0-9]?)?)?)?$/;
const hoursMinutes = /^([0-2]?[0-9]):([0-5][0-9])$/;

const Directions = () => {
    const [start, setStart] = React.useState("");
    const urlParams = new URLSearchParams(window.location.search);
    const searchParam = urlParams.get("search");
    const [dest, setDest] = React.useState(searchParam ? searchParam.split(",") : []);
    const [locations, setLocations] = React.useState([]);
    const [directions, setDirections] = React.useState([]);
    const [distance, setDistance] = React.useState(0.0);
    const [wait, setWait] = React.useState(0.0);
    const [cidCookie] = useCookies(['cid']);
    const [cookies, setCookie] = useCookies(['current', 'pl', 'roads', 'favorites', 'mode', 'norelock', 'excludes', 'fast', 'multi']);
    const [currentLocation, setCurrentLocation] = React.useState(false);
    const [minholesize, setMinholesize] = React.useState('2');
    const [expires, setExpires] = React.useState(0);
    const [distances, setDistances] = React.useState({});
    const [waits, setWaits] = React.useState({});
    const [expirations, setExpirations] = React.useState({});
    const [reachableRoads, setReachableRoads] = React.useState(0);
    const [totalRoads, setTotalRoads] = React.useState(1);
    const [unexplored, setUnexplored] = React.useState([]);
    const [unexploredTypes, setUnexploredTypes] = React.useState({});
    const [reported, setReported] = React.useState([]);
    const [status, setStatus] = React.useState(null);
    const [version, setVersion] = React.useState();
    const [currentVersion, setCurrentVersion] = React.useState();
    const [newRoad, setNewRoad] = React.useState();
    const track = useContext(Tracking);
    const instructionsText = React.createRef();

    const isWatchCurrentLocation = React.useCallback(() => {
        return cookies.current === "current";
    }, [cookies]);

    React.useEffect(() => {
        if (currentLocation !== isWatchCurrentLocation()) {
            setCurrentLocation(isWatchCurrentLocation());
        }
    }, [currentLocation, isWatchCurrentLocation])

    function AddRoad(props) {
        const [road, setRoad] = React.useState("");
        const [roadExpires, setRoadExpires] = React.useState("");
        const [roadSize, setRoadSize] = React.useState("2");
        const [posX, setPosX] = React.useState(0);
        const [posY, setPosY] = React.useState(0);
        const {newRoad, setNewRoad} = props;
        const roadExpiresField = React.createRef();
        const map = React.createRef();

        function setRoadFromDropdown(event, dropdown) {
            setRoad(dropdown.value);
        }

        function updateRoadSize(e, radio) {
            setRoadSize(radio.value);
        }

        function updateRoadExpires(e, input) {
            let value = input.value;
            let matches = hoursMinutesEdit.exec(value);
            if (matches) {
                setRoadExpires(value);
            }
        }

        function ready() {
            let match = hoursMinutes.exec(roadExpires);
            return (start !== "" && road !== "" && match);
        }

        React.useEffect(() => {
            if (newRoad) {
                ReactDOM.unstable_batchedUpdates(() => {
                    setStart(newRoad.zone);
                    setRoad(newRoad.portal);
                    const expires = newRoad.expires;
                    if (expires.hours && expires.minutes) {
                        const value = expires.hours + ":" + expires.minutes;
                        let matches = hoursMinutesEdit.exec(value);
                        if (matches) {
                            setRoadExpires(value);
                        }
                    }
                    setRoadSize("" + newRoad.size);
                });
            }
        }, [newRoad, setNewRoad]);

        React.useEffect(() => {
            const canvas = map.current;
            if (canvas) {
                const ctx = canvas.getContext('2d');
                let rect = canvas.getBoundingClientRect();
                const w = canvas.width;
                const h = canvas.height;
                if (posY === 0 || posX === 0) {
                    setPosX(rect.width / 2);
                    setPosY(rect.height / 2);
                    return;
                }
                ctx.clearRect(0, 0, w, h);
                ctx.beginPath();
                ctx.moveTo(.1 * w, h / 2);
                ctx.lineTo(w / 2, 0);
                ctx.lineTo(.9 * w, h / 2);
                ctx.lineTo(w / 2, h);
                ctx.closePath();
                ctx.fillStyle = 'lightgrey';
                ctx.fill();

                ctx.beginPath();
                ctx.ellipse(posX / rect.width * w, posY / rect.height * h, 10, 10, Math.PI / 4, 0, 2 * Math.PI);
                ctx.fillStyle = roadSize === '2' ? 'green' : roadSize === '7' ? "blue" : roadSize === '20' ? "yellow" : "black";
                ctx.fill();
            }
        }, [map, posX, posY, roadSize]);

        function setPosition(event) {
            const canvas = map.current;
            if (canvas) {
                let rect = map.current.getBoundingClientRect();
                let x = event.clientX - rect.left;
                let y = event.clientY - rect.top;
                setPosX(x);
                setPosY(y);
            }
        }

        function addRoad() {
            if (ready()) {
                const canvas = map.current;
                if (canvas) {
                    let rect = map.current.getBoundingClientRect();
                    // Translate x and y from a h/2, h/2 center
                    const transX = posX - rect.width / 2;
                    const transY = posY - rect.height / 2;
                    // Rotate -45
                    const rotX = (transX + transY) / Math.sqrt(2);
                    const rotY = (transY - transX) / Math.sqrt(2);
                    const gameX = rotX / rect.width * 900 * Math.sqrt(2);
                    const gameY = -rotY / rect.height * 900 * Math.sqrt(2);
                    let match = hoursMinutes.exec(roadExpires);
                    const endTime = Math.round(new Date().getTime() / 1000 + parseInt(match[1]) * 3600 + parseInt(match[2]) * 60);

                    // Until I add a little map to click on
                    const payload = {
                        clientID: cookies.cid,
                        sourceClusterDisplayName: start,
                        targetClusterDisplayName: road,
                        maxCapacity: roadSize,
                        remainingCapacity: roadSize,
                        posX: gameX,
                        posY: gameY,
                        endTime: "" + endTime,
                    }
                    setStatus("Adding " + road + "...");
                    fetch(
                        "https://albion.reznok.com/api/map/roa?v=1.3.1",
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify(payload)
                        }
                    ).then(response => response.json()).then(result => {
                        if (result.error) {
                            setStatus(result.error);
                        } else {
                            ReactDOM.unstable_batchedUpdates(() => {
                                setStatus("Added " + road + ".");
                                setPosX(0);
                                setPosY(0);
                                setRoadExpires("");
                                setRoad("");
                                setRoadSize(2);
                                setNewRoad(null);
                            });
                            payload.clientID = null;
                            track("added_road", payload);
                        }
                    });
                }
            }
        }

        return <>
            {start !== "" ? <>
                <span style={{"display": "block"}}>Add Road in {start}</span>
                <div className="roaddropdown">
                    <Dropdown
                        placeholder='Target Road Location'
                        search
                        selection
                        value={road}
                        onChange={setRoadFromDropdown}
                        options={locations}
                    />
                </div>
                <canvas ref={map} onClick={setPosition} id="map"/>
                <Form>
                    <FormField>
                        <Radio className="roadsize" label='2' name='roadSizeGroup' value='2' checked={roadSize === '2'}
                               onChange={updateRoadSize}/>
                        <Radio className="roadsize" label='7' name='roadSizeGroup' value='7' checked={roadSize === '7'}
                               onChange={updateRoadSize}/>
                        <Radio className="roadsize" label='20' name='roadSizeGroup' value='20'
                               checked={roadSize === '20'} onChange={updateRoadSize}/>
                    </FormField>
                </Form>
                <Input ref={roadExpiresField} label="Expires in" onChange={updateRoadExpires} value={roadExpires}
                       size="mini" placeholder="HH:MM"/>
                <Button disabled={!ready()} style={{"display": "inline"}} onClick={addRoad} size="mini">Add</Button>
            </> : ""} </>;
    }

    React.useEffect(() => {
        function getVersion() {
            return fetch(
                "https://api.albionroamapp.com/version?site_id=" + SITE_ID
            ).then(response => response.json()).then(result => {
                const version = result.version;
                setVersion(version);
            });
        }

        getVersion();

        let interval = setInterval(() => {
            getVersion();
        }, 600000);

        return () => {
            clearInterval(interval);
        }
    }, []);

    React.useEffect(() => {
        if (!currentVersion && version) {
            setCurrentVersion(version);
        } else if (currentVersion && version) {
            if (currentVersion !== version) {
                console.log("Version updated, reloading");
                window.location.reload();
            }
        }
    }, [version, currentVersion]);

    React.useEffect(() => {
        setDefaultData({cid: cidCookie.cid});
    }, [cidCookie]);

    const runningAutocomplete = React.useRef(false);
    React.useEffect(() => {
        if (!runningAutocomplete.current) {
            runningAutocomplete.current = true;
            fetch(
                "https://ws5xuno6ue.execute-api.us-west-2.amazonaws.com/default/albion_roamapp",
                {
                    method: "GET"
                }
            ).then(response => {
                return response.json();
            }).then(data => {
                runningAutocomplete.current = false;
                const locations = data.autocomplete.map(l => {
                    return {
                        key: l.trim(), value: l.trim(), text: l.trim()
                    }
                });
                setLocations(locations);
                track("autocomplete_loaded", {});
            });
        }
    }, [track]);

    const isRoads = React.useCallback(() => {
        return cookies.roads === "roads";
    }, [cookies]);

    const isMultiSelect = React.useCallback(() => {
        return cookies.multi === "multi" || dest.length > 1;
    }, [cookies, dest.length]);

    const isNoRelock = React.useCallback(() => {
        return cookies.norelock === "norelock";
    }, [cookies]);

    function clearDirections() {
        setDirections([]);
        setDistance(0);
        setExpires(0);
        setReachableRoads(0);
        setUnexplored([]);
        setTotalRoads(0);
        setReported([]);
    }

    if (!cookies.roads) {
        setCookie('roads', 'roads', cookieOptions);
    }

    const findDirections = React.useCallback(() => {
        const abortController = new AbortController();
        if (start !== "" && dest !== "") {
            if (start === dest) {
                ReactDOM.unstable_batchedUpdates(() => {
                    setStatus("You have arrived.");
                    clearDirections();
                });
                return;
            }
            setStatus("Getting directions from " + start + " to " + dest + "...");
            fetch(
                "https://ws5xuno6ue.execute-api.us-west-2.amazonaws.com/default/albion_roamapp",
                {
                    signal: abortController.signal,
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        "from": start,
                        "to": dest.length === 1 ? dest[0] : null,
                        "search": dest.length > 1 ? dest : null,
                        "minholesize": minholesize,
                        "roads": isRoads() ? "roads" : "noroads",
                        "lock": cookies.pl,
                        "tos": cookies.favorites == null ? null : cookies.favorites.map(f => Array.isArray(f) ? JSON.stringify(f) : f),
                        "cid": cookies.cid,
                        "norelock": isNoRelock() ? "norelock" : "relock",
                        "excludes": cookies.excludes,
                        "fast": cookies.fast,
                    })
                }
            ).then(response => response.json())
                .then(data => {
                    let path = data.path;
                    if (path && path.length !== 0) {
                        ReactDOM.unstable_batchedUpdates(() => {
                            setDirections(path);
                            setDistance(data.distance);
                            setWait(data.wait ? data.wait : 0);
                            setExpires(data.expires);
                            setReachableRoads(data.reachableRoads);
                            setTotalRoads(data.totalRoads);
                            let batch = data.batch;
                            if (batch) {
                                let distances = {};
                                let expirations = {};
                                let waits = {};
                                batch.forEach(r => {
                                    if (r.name.startsWith("[")) {
                                        r.name = JSON.parse(r.name);
                                    }
                                    waits[r.name] = r.wait !== 0 ? format(r.wait * 1000) : null;
                                    distances[r.name] = format(r.distance / 10 * 1000);
                                    expirations[r.name] = format(r.expires * 1000);
                                });
                                setWaits(waits);
                                setDistances(distances);
                                setExpirations(expirations);
                            }
                            if (data.unexplored) {
                                setUnexploredTypes(data.unexploredTypes);
                                setUnexplored(data.unexplored);
                                setReported(data.reported);
                            }
                            setStatus(null);
                        });
                        track("directions", {from: start, to: dest})
                    } else {
                        track("directions_unknown", {from: start, to: dest})
                        ReactDOM.unstable_batchedUpdates(() => {
                            setStatus("Path currently unknown. ROAM to discover more connections. Adding each road" +
                                " that you see in a zone will map out more directions.");
                            clearDirections();
                        });
                    }
                }).catch((error) => {
                if (!abortController.signal.aborted) {
                    setStatus("Fetching directions failed");
                    track("directions_failed", {from: start, to: dest, error: error});
                }
            })
        }
        return () => abortController.abort();
    }, [start, dest, minholesize, cookies, isRoads, isNoRelock, track]);

    React.useEffect(findDirections, [start, dest, minholesize, cookies, isRoads, isNoRelock]);

    function updateMinholesize(event, radio) {
        setMinholesize(radio.value);
        track("set_party_size", {partySize: radio.value});
    }

    function updatePortalLock(event, list) {
        setCookie('pl', list.value, cookieOptions);
        track("update_portal_lock", {portal: list.value});
    }

    function updateRoadsCheckbox(event, checkbox) {
        let value = checkbox.checked ? "roads" : "noroads";
        setCookie('roads', value, cookieOptions);
        track("update_roads", {roads: value});
    }

    const updateMultiSelect = React.useCallback((event, checkbox) => {
        let value = checkbox.checked ? "multi" : "single";
        setCookie('multi', value, cookieOptions);
        track("update_multi", {roads: value});
    }, [setCookie, track]);

    function updateWatchCurrentLocation(event, checkbox) {
        let value = checkbox.checked ? "current" : "nocurrent";
        setCookie('current', value, cookieOptions);
        track("update_current_location", {current: value});
    }

    function updateNoReLockCheckbox(event, checkbox) {
        let value = checkbox.checked ? "norelock" : "relock";
        setCookie('norelock', value, cookieOptions);
        track("update_relock", {relock: value});
    }

    function updateDarkMode(event, checkbox) {
        let value = checkbox.checked ? "dark" : "light";
        setCookie('mode', value, cookieOptions);
        track("update_dark_mode", {darkMode: value});
    }

    function isDarkMode() {
        return cookies.mode === "dark";
    }

    function updateFastTravel(event, checkbox) {
        let value = checkbox.checked ? "fast" : "nofast";
        setCookie('fast', value, cookieOptions);
        track("update_fast_travel", {fast: value});
    }

    function isFastTravel() {
        return cookies.fast === "fast";
    }

    function addFavorite(value) {
        let favorites = cookies.favorites;
        if (favorites && value) {
            let hasFavorite = favorites.includes(value);
            if (!hasFavorite) {
                favorites.push(value);
                setCookie('favorites', favorites, cookieOptions);
            }
        } else {
            setCookie('favorites', [value], cookieOptions);
        }
        track("added_favorite", {favorite: value});
    }

    function addDestToFavorites() {
        addFavorite(dest);
    }

    function addStartToFavorites() {
        addFavorite(start);
    }

    function setToFavorite(event, button) {
        const value = button.value;
        if (start === "") {
            if (Array.isArray(value)) {
                setStart(value[0]);
            } else {
                setStart(value);
            }
            track("set_start_favorite", {location: value});
        } else {
            if (Array.isArray(value)) {
                setDest(value);
            } else {
                setDest([value]);
            }
            track("set_dest", {location: value});
            track("set_dest_favorite", {location: value});
        }
    }

    function excludeZone(exitId) {
        return () => {
            const zone = exitId.substring(exitId.lastIndexOf("@") + 1);
            fetch(
                "https://ws5xuno6ue.execute-api.us-west-2.amazonaws.com/default/albion_roamapp",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        "from": zone
                    })
                }
            ).then(response => response.json())
                .then(data => {
                    addExclude(data.name);
                });
        }
    }

    function addExclude(value) {
        let excludes = cookies.excludes;
        if (excludes && value) {
            let hasFavorite = excludes.includes(value);
            if (!hasFavorite) {
                excludes.push(value);
                setCookie('excludes', excludes, cookieOptions);
            }
        } else {
            setCookie('excludes', [value], cookieOptions);
        }
        track("added_exclude", {favorite: value});
    }

    function removeExclude(event, button) {
        let excludes = cookies.excludes;
        if (excludes) {
            const index = excludes.indexOf(button.value);
            if (index > -1) {
                excludes.splice(index, 1);
                setCookie('excludes', excludes, cookieOptions);
            }
        }
        track("removed_exclude", {favorite: button.value});
    }

    function removeFavoriteDest(event, button) {
        let favorites = cookies.favorites;
        if (favorites) {
            const index = favorites.indexOf(button.value);
            if (index > -1) {
                favorites.splice(index, 1);
                setCookie('favorites', favorites, cookieOptions);
            }
        }
        track("removed_favorite", {favorite: button.value});
    }

    function moveUp(favorite) {
        let favorites = cookies.favorites;
        if (favorites) {
            const newFavorites = [...favorites];
            const index = newFavorites.indexOf(favorite);
            if (index > 0) {
                newFavorites[index] = favorites[index - 1];
                newFavorites[index - 1] = favorite;
                setCookie('favorites', newFavorites, cookieOptions);
            }
        }
    }

    function moveDown(favorite) {
        let favorites = cookies.favorites;
        if (favorites) {
            const newFavorites = [...favorites];
            const index = newFavorites.indexOf(favorite);
            if (index < newFavorites.length - 1) {
                newFavorites[index] = favorites[index + 1];
                newFavorites[index + 1] = favorite;
                setCookie('favorites', newFavorites, cookieOptions);
            }
        }
    }

    const StartDirections = React.useMemo(() => {
        function startLocation(event, {value}) {
            setStart(value);
            track("set_start", {location: value});
        }

        return <>
            <div className="dropdown">
                <Dropdown
                    placeholder='Location'
                    search
                    selection
                    value={start}
                    onChange={startLocation}
                    options={locations}
                />
            </div>
        </>
    }, [start, locations, track]);

    const DestDirections = React.useMemo(() => {
        function destLocation(event, {value}) {
            setDest(isMultiSelect() ? value : [value]);
            track("set_dest", {location: value});
        }

        return <>
            <Checkbox id='updateMultiSelect' disabled={dest.length > 1}
                      onChange={updateMultiSelect} checked={isMultiSelect()}/>Multiple Destinations<br/>
            <div className="dropdown">
                {isMultiSelect() ?
                    <Dropdown
                        placeholder='Location'
                        search
                        multiple
                        selection
                        value={dest}
                        onChange={destLocation}
                        options={locations}
                    />
                    :
                    <Dropdown
                        placeholder='Location'
                        search
                        selection
                        value={dest[0]}
                        onChange={destLocation}
                        options={locations}
                    />

                }
            </div>
        </>
    }, [dest, locations, track, isMultiSelect, updateMultiSelect]);

    function showHideInstructions(event) {
        event.preventDefault();
        const text = instructionsText.current;
        if (text) {
            if (text.style.display === "none") {
                text.style.display = "block";
            } else {
                text.style.display = "none";
            }
        }
    }

    const updateRoad = React.useCallback((road) => {
        if (JSON.stringify(road) !== JSON.stringify(newRoad)) {
            setNewRoad(road);
        }
    }, [newRoad]);

    const updateCurrentLocation = React.useCallback((location) => {
        if (isWatchCurrentLocation()) {
            if (locations.some(cluster => cluster.value === location)) {
                if (start !== location) {
                    console.log("Setting new location: " + location);
                    setStart(location);
                    setNewRoad(null);
                }
            }
        }
    }, [locations, start, isWatchCurrentLocation]);

    return <div>
        <h1><a href="/"><img src={isDarkMode() ? "roamapp-name-dark.png" : "roamapp-name.png"} height="90"
                             alt="ROAMapp"
                             style={{margin: "20px"}}/></a></h1>
        <style type="text/css">
            {":root { background-color: #FFFFFF; }"}
            {isDarkMode() ? darkMode : ""}
        </style>
        <a href="/" style={{"display": "block"}} onClick={showHideInstructions}>Instructions</a>
        <div style={{"display": "none"}} ref={instructionsText} className="instructions-text">
            <ol>
                <li>To get directions, enter a start location and a destination. If a path is known, it will
                    display a list of exits to take.
                </li>
                <li>You can use the <span role="img" aria-label="favorite" title="favorite">⭐</span>
                    to add the start or destination to your list of favorites. Clicking on a
                    favorite will set it to your start locaiton if none is set or if start is set, it will set
                    it to your destination.
                </li>
                <li>When a start location is set, you can add a portal that you see in that zone using the form
                    below the destination. Enter the name of the portal, its size, when it expires in HH:MM format
                    and click on the map location on the right diamond.
                </li>
                <li>As you move through the game, you can click on the exit you just took to change zones to set
                    your start location to the new zone that you entered so you can add more portals.
                </li>
                <li>The search feature lets you find zones in the map that have properties that you are
                    interested in, like tier, features and type of zone.
                </li>
                <li>The portal lock can help you find paths that don't require a different lock.</li>
                <li>Setting fast travel will allow the pathing system to use cities to move to another city.</li>
                <li>No relock makes it so that you don't go into a city from the black zone and change your
                    current portal lock.
                </li>
                <li>Setting a party size higher than 2 will add wait time for road portals so that it can
                    try and route around small road portals and potentially find the destination faster.
                </li>
                <li>Start Road Screen Capture will ask to you to share the Albion application. While sharing it will
                    monitor
                    the screen for the current location and when you hover over portals it will attempt to
                    automatically add them - hover for a few seconds if it doesn't work right away.
                    You must have "Experimental Web Platform features" enabled in
                    chrome://flags.
                </li>
            </ol>

        </div>
        <Checkbox id='updateWatchCurrentLocation'
                  onChange={updateWatchCurrentLocation} checked={isWatchCurrentLocation()}/>
        <span>&nbsp;Use Current Location&nbsp;</span>
        <br/>
        {StartDirections}
        <Button className="favorite-button" onClick={addStartToFavorites}><span role="img" aria-label="favorite"
                                                                                title="favorite">⭐</span></Button>
        <br/>
        {DestDirections}
        <Button className="favorite-button" onClick={addDestToFavorites}><span role="img" aria-label="favorite"
                                                                               title="favorite">⭐</span></Button>
        <AddRoad newRoad={newRoad} setNewRoad={setNewRoad}/>
        <a style={{"display": "block"}} href="/search">Search</a>
        {status != null ? <span className="status"><br/><br/>{status}</span> : ""}
        <List className="directions">
            {directions.map(exit => {
                return <List.Item className="direction" key={exit.id + exit.name}>
                    <span onClick={() => setStart(exit.zone)}
                          title={exit.zone + ", " + exit.type + ", T" + exit.tier}>{exit.name ? exit.name + (exit.maximum ? " ("
                        + exit.maximum + ")" : "") : ""}</span>
                    <span onClick={excludeZone(exit.id)} className="exclude" role="img" aria-label="exclude"
                          title="exclude zone">❌</span>
                </List.Item>
            })}
        </List>
        <span>{distance > 0 ? Math.round(distance) + " meters (" + format(distance / 10 * 1000) + " by Swiftclaw)" : ""}
            {wait > 0 ? " Waiting: " + format(wait * 1000) : ""}
        </span>
        <br/>
        {expires > 0 ? <span>{"Expires in " + format(expires * 1000)}<br/></span> : ""}
        {reachableRoads > 0 ?
            <span>{"Seen: " + reachableRoads + "/" + totalRoads + " " + Math.round(100 * reachableRoads / totalRoads) + "%"}<br/></span> : ""}
        <List>
            {unexplored.length > 0 ? <List.Item key="unexplored">Unexplored:</List.Item> : ""}
            {unexplored.map(cluster => {
                return <List.Item key={cluster}
                                  title={unexploredTypes[cluster]["type"] + ", T" + unexploredTypes[cluster]["tier"]}><b>{cluster}</b></List.Item>
            })}
        </List>
        <List>
            {reported.length > 0 ? <List.Item key="unexplored">Reported:</List.Item> : ""}
            {reported.map(cluster => {
                return <List.Item key={cluster}><b>{cluster}</b></List.Item>
            })}
        </List>
        {start && dest ? <><Button style={{"display": "block"}}
                                   onClick={findDirections}>Refresh</Button></> : ""}
        <br/>
        <Input label="Party Size" onChange={updateMinholesize} value={minholesize} size="mini"/>
        <List>
            {cookies.excludes && cookies.excludes.length > 0 ? <label>Excludes</label> : ""}
            {cookies.excludes && cookies.excludes.map(exclude => {
                return <List.Item key={exclude}>
                    <Button onClick={removeExclude} value={exclude}><span role="img"
                                                                          aria-label="remove">❌</span></Button>
                    <span>{exclude}</span>
                </List.Item>
            })}
        </List>
        <List>
            {cookies.favorites && cookies.favorites.length > 0 ? <label>Favorites</label> : ""}
            {cookies.favorites && cookies.favorites.map(favorite => {
                return <List.Item className="listitem" key={favorite}>
                    <Button onClick={removeFavoriteDest} value={favorite} size="small"><span role="img"
                                                                                             aria-label="remove">❌</span></Button>
                    <Button onClick={setToFavorite} value={favorite} size="small">{favorite}</Button>
                    <span id={favorite}>
                        {distances[favorite]}{waits[favorite] ? "+" + waits[favorite] : ""}
                        {expirations[favorite] != null ? (expirations[favorite] !== "0:00" ? " expires " + expirations[favorite] + " " : "") : ""}</span>
                    <div className="updown"><span onClick={() => moveUp(favorite)}>↑</span> <span
                        onClick={() => moveDown(favorite)}>↓</span></div>
                </List.Item>
            })}
        </List>
        <span>Includes public data from <a href="https://map.portaler.zone/" target="_blank"
                                           rel="noopener noreferrer">Portaler</a></span><br/><br/>
        <span>Portal Lock</span><br/>
        <Dropdown clearable options={portalOptions} selection onChange={updatePortalLock} value={cookies.pl}/>
        <br/>
        <Checkbox id='updateRoadsCheckbox'
                  onChange={updateRoadsCheckbox} checked={isRoads()}/>
        <span>&nbsp;Use Roads&nbsp;</span>
        <Checkbox id='updateNoRelockCheckbox'
                  onChange={updateNoReLockCheckbox} checked={isNoRelock()}/>
        <span>&nbsp;No relock</span>
        <br/>
        <Checkbox id='darkMode'
                  onChange={updateDarkMode} checked={isDarkMode()}/>
        <span>&nbsp;Dark Mode&nbsp;</span>
        <Checkbox id='fastTravel'
                  onChange={updateFastTravel} checked={isFastTravel()}/>
        <span>&nbsp;Fast travel</span>
        <br/>
        <Capture updateRoad={updateRoad} updateLocation={updateCurrentLocation}/>
        <br/>
        <a href="/?cid=">Logout</a>
        <div className="privacy">
            <span className="privacy-link">Privacy</span>
            <List className="privacy-text">
                <List.Item>1. ROAMapper collects the current location and the Roads that you enter into the webapp
                    and associates them with your client id.</List.Item>
                <List.Item>2. ROAMapper does not collect your character name, guild, alliance, or any other
                    identifying
                    in game information about you.</List.Item>
                <List.Item>3. ROAM will not disclose to any 3rd-party routes that you map.</List.Item>
                <List.Item>4. Favorites, excluded zones, and other settings are stored only client side and not
                    stored
                    on the server.</List.Item>
                <List.Item>5. ROAM will purge all data associated with your client id at your request. Message us on
                    Discord.</List.Item>
            </List>
        </div>
        {version ? <span>Version: {version}</span> : ""}
    </div>;
};

let styleLink = document.createElement("link");
styleLink.rel = "stylesheet";
styleLink.href = "https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css";
document.head.appendChild(styleLink);

ReactDOM.render(
    <App>
    </App>,
    document.getElementById("root")
);

export default App;
