import React from "react";

let defaultData = {};
const Tracking = React.createContext(null);
const track = (event, data) => {
    let message = {"event": event};
    let value = {...message, ...defaultData, ...data};
    fetch("https://api.albionroamapp.com/report",
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(value),
        });
    return null;
}
const setDefaultData = (data) => {
    defaultData = data;
}

export {Tracking, track, setDefaultData};