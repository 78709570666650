import React from "react";
import World from './zones.json';
import {closest, distance} from "fastest-levenshtein";
import ReactDOM from "react-dom";
import {Checkbox} from "semantic-ui-react";

const displayMediaOptions = {
    video: {
        cursor: "always"
    },
    audio: false
}

let textDetect = null;
try {
    // eslint-disable-next-line no-undef
    textDetect = new TextDetector();
} catch {
    console.log("Experimental Web Platform Features disabled or not available");
}

const displaynames = World.map(cluster => cluster.displayname.trim());
const closesPattern = /([0-9]?[0-9])[^0-9]+([0-9]?[0-9])?/;

// Find the closest match
function findMatch(color) {
    const match = [
        {r: 139, g: 180, b: 60},
        {r: 54, g: 155, b: 204},
        {r: 177, g: 129, b: 19}
    ];
    let bestDistance = -1;
    let bestMatch;
    for (let i = 0; i < match.length; i++) {
        let distance = (color.r - match[i].r) * (color.r - match[i].r) +
            (color.g - match[i].g) * (color.g - match[i].g) +
            (color.b - match[i].b) * (color.b - match[i].b);
        if (bestDistance === -1 || bestDistance > distance) {
            bestDistance = distance;
            bestMatch = i;
        }
    }
    switch (bestMatch) {
        case 0:
            return 2;
        case 1:
            return 7;
        case 2:
            return 20;
        default:
            return 0;
    }
}

function getColor(data) {
    const length = data.data.length;
    let i = 0;
    let count = 0;
    let rgb = {r: 0, g: 0, b: 0};
    while ((i += 4) < length) {
        ++count;
        rgb.r += data.data[i];
        rgb.g += data.data[i + 1];
        rgb.b += data.data[i + 2];
    }

    // ~~ used to floor values
    rgb.r = ~~(rgb.r / count);
    rgb.g = ~~(rgb.g / count);
    rgb.b = ~~(rgb.b / count);

    return rgb;
}

// const copyColors = [
//     {r: 0xFF, g: 0xFF, b: 0xFF}, // text
//     {r: 0xFF, g: 0x54, b: 0x3C}, // red
//     {r: 0xFF, g: 0x4E, b: 0x39}, // red
//     {r: 0xFB, g: 0x4E, b: 0x3a}, // red
//     {r: 0xFF, g: 0x56, b: 0x3F}, // red
//     {r: 0xd8, g: 0x5e, b: 0x51}, // red
//     {r: 0xe1, g: 0xcb, b: 0xbf}, // closes in
// ]
//
// function checkColors(data, i) {
//     //     text: ffffff size: 4b2b08 green: bbf630
//     //     road of avalon: d2bcaf red: ff543c
//     let r = data[i + 0];
//     let g = data[i + 1];
//     let b = data[i + 2];
//     return copyColors.find(rgb => {
//         return Math.abs(r - rgb.r) + Math.abs(g - rgb.g) + Math.abs(b - rgb.b) < 30;
//     });
// }

function Capture(props) {
    const videoElem = React.createRef();
    const mapElem = React.createRef();
    const zoneElem = React.createRef();
    const sizeElem = React.createRef();
    const portalElem = React.createRef();
    const portal2Elem = React.createRef();
    const expiresElem = React.createRef();
    const scaledElem = React.createRef();
    const [started, setStarted] = React.useState(false);
    const [road, setRoad] = React.useState({zone: null, portal: null, expires: null, size: null});
    const {updateRoad, updateLocation} = props;
    const [debug, setDebug] = React.useState(false);

    async function startCapture() {
        try {
            videoElem.current.srcObject = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions);
            setStarted(true);
        } catch (err) {
            console.error("Error: " + err);
        }
    }

    /*
    {boundingBox: DOMRectReadOnly, cornerPoints: Array(0), rawValue: "Road of Avalon to"}
     */

    React.useEffect(() => {
        async function loop() {
            if (videoElem.current && scaledElem.current) {
                const scaled = scaledElem.current.getContext('2d');
                const vw = videoElem.current.videoWidth;
                const vh = videoElem.current.videoHeight;
                const tw = vw / 3840.0;
                const hratio = 2160.0 / vh * tw;
                const offset = 3840.0 * tw - 3840.0 * tw / hratio;
                console.log(`${vw} ${vh} ${hratio} ${offset}`);
                scaled.drawImage(videoElem.current, offset, 0, vw - offset, vh, 0, 0, 3840, 2160);
            }
            if (scaledElem.current && mapElem.current && videoElem.current && videoElem.current.videoWidth && zoneElem.current && sizeElem.current && portalElem.current && portal2Elem.current && expiresElem.current) {
                let timer = null;
                let portal = null;
                let color = null;
                const map = mapElem.current.getContext('2d');
                map.drawImage(scaledElem.current, 2800, 1400, 1040, 670, 0, 0, 1040, 670);
                const mapImage = map.getImageData(0, 0, 1040, 760);
                let mapBoxes = await textDetect.detect(mapImage);
                const box = mapBoxes.find(box => box.rawValue.includes("Road of Avalon"));
                if (box) {
                    const bb = box.boundingBox;

                    const portalctx = portalElem.current.getContext('2d');
                    portalctx.drawImage(mapElem.current, bb.left - 137, bb.top - 23, 720, 225, 0, 0, 725, 225);

                    // Let's try and figure out the size
                    const size = sizeElem.current.getContext('2d');
                    size.drawImage(mapElem.current, bb.left - 70, bb.top + 20, 10, 10, 0, 0, 10, 10);
                    color = getColor(size.getImageData(0, 0, 10, 10));
                    // g {r: 139, g: 180, b: 60}
                    // b {r: 54, g: 155, b: 204}
                    // y {r: 177, g: 129, b: 19}

                    mapBoxes = await textDetect.detect(portalctx.getImageData(130, 50, 400, 50));
                    mapBoxes.forEach(box => {
                        const expires = box.rawValue;
                        const s = closest(expires, displaynames);
                        console.log("Road: " + s);
                        portal = s;
                    });
                    mapBoxes = await textDetect.detect(portalctx.getImageData(415, 125, 725, 125));
                    mapBoxes.forEach(box => {
                        const expires = box.rawValue;
                        const [/* full match */, num1, num2] = closesPattern.exec(expires) || [];
                        if (expires.includes("h") || (expires.includes("m") && !expires.includes("s"))) {
                            timer = {hours: num1, minutes: num2 ? num2 : 0, seconds: 0};
                        } else if (expires.includes("s")) {
                            timer = {hours: 0, minutes: num1, seconds: num2 ? num2 : 0};
                        }
                    });
                }

                // 695140
                if (zoneElem.current) {
                    const zone = zoneElem.current.getContext('2d');
                    zone.drawImage(scaledElem.current, 3330, 2070, 270, 60, 0, 0, 270, 60);
                    const zoneImage = zone.getImageData(0, 0, 270, 60);
                    const zoneBoxes = await textDetect.detect(zoneImage);

                    let currentZone = null;
                    zoneBoxes.forEach(box => {
                        console.log("Current zone: " + box.rawValue);
                        currentZone = closest(box.rawValue, displaynames);
                        const d = distance(box.rawValue, currentZone);
                        if (d <= 2) {
                            if (!currentZone.startsWith("Conquerors")) {
                                updateLocation(currentZone);
                            }
                        }
                    });
                    const newRoad = {
                        zone: currentZone,
                        portal: portal,
                        expires: timer,
                        size: color ? findMatch(color) : null,
                    };
                    if (newRoad.zone && newRoad.portal && newRoad.expires && newRoad.size) {
                        ReactDOM.unstable_batchedUpdates(() => {
                            setRoad(newRoad);
                            updateRoad(newRoad);
                            console.log(newRoad);
                        });
                    }
                }
            }
        }

        if (started) {
            const interval = setInterval(loop, 1000);
            return () => clearInterval(interval);
        }
    }, [started, scaledElem, mapElem, videoElem, zoneElem, portalElem, sizeElem, portal2Elem, expiresElem, updateRoad, updateLocation]);

    function stopCapture(evt) {
        let tracks = videoElem.current.srcObject.getTracks();

        tracks.forEach(track => track.stop());
        videoElem.current.srcObject = null;
        setStarted(false);
    }

    return <div>
        <br/>
        <br/>
        <p>
            {textDetect ? "" :
                <span style={{"display": "block"}}>You must have 'Experimental Web Platform Features' enabled to use this</span>}
            {started ? "" :
                <button disabled={!textDetect} onClick={startCapture} id="start">Start Road Screen Capture</button>}
            {started ? <button onClick={stopCapture} id="stop">Stop Road Screen Capture</button> : ""}
        </p>
        {road.zone ?
            <h3>Road
                Detected: {road.zone} {road.portal} {road.size} {road.expires ? road.expires.hours + "h " + road.expires.minutes + "m" + road.expires.seconds + "s" : ""}</h3>
            : ""}

        <canvas width={725} height={225} ref={portalElem} id="portal"/>
        <br/>
        <Checkbox id='updateDebugCheckbox'
                  onChange={(e, v) => {
                      setDebug(v.checked);
                  }} checked={debug}/>
        <span>&nbsp;Debug&nbsp;</span>

        <div style={{"display": debug ? "block" : "none"}}>
            <video ref={videoElem} id="video" autoPlay/>
            <br/>
            <canvas width={1040} height={670} ref={mapElem} id="cropped"/>
            <br/>
            <canvas width={270} height={60} ref={zoneElem} id="currentzone"/>
            <br/>
            <canvas width={10} height={10} ref={sizeElem}/>
            <br/>
            <canvas width={725} height={225} ref={portal2Elem} id="portal2"/>
            <br/>
            <canvas width={725} height={225} ref={expiresElem} id="expires"/>
            <br/>
            <canvas width={3840} height={2160} ref={scaledElem} id="scaled"/>
            <br/>
        </div>
    </div>;
}

export default Capture;

