import React, {useContext} from 'react';
import './App.css';
import World from './world-search.json';
import { Dropdown} from "semantic-ui-react";
import {useCookies} from "react-cookie";
import {Tracking, setDefaultData} from "./Tracking";

const darkMode = "" +
    ":root { filter: invert();} " +
    "img {filter: invert();}";

const roadOptions = [
    {key: "roads", text:"Only roads", value: "roads",},
    {key: "noroads", text:"Only normal zones", value: "noroads"}
];

const Search = () => {
    const [world, setWorld] = React.useState([]);
    const [groups, setGroups] = React.useState({});
    const [cidCookie] = useCookies(['cid']);
    const track = useContext(Tracking);

    React.useEffect(() => {
        let cid = cidCookie.cid;
        if (!cid) cid = "unknown";
        setDefaultData({cid: cid});
    }, [cidCookie]);

    // Find all the options once
    React.useEffect(() => {
        // Sort them by normal vs roads and then by name
        const world = World.sort((a, b) => {
            if (a.road && !b.road) {
                return 1;
            }
            if (!a.road && b.road) {
                return -1;
            }
            return a.displayname < b.displayname ? -1 : a.displayname > b.displayname ? 1 : 0;
        });

        function group(reducer) {
            return Array.from(world.reduce(reducer, new Set()))
                .filter(t => t)
                .sort().map(t => {
                    return {key: t, text: t, value: t};
                });
        }

        const groups = {};

        groups.tiers = group((a, c) => a.add(c.tier));
        groups.typeNames = group((a, c) => a.add(c.type_name));
        groups.timeregions = group((a, c) => a.add(c.timeregion));
        groups.features = group((a, c) => {
            c.minimapmarkers.forEach(m => a.add(m.type));
            return a;
        });
        groups.territories = group((a, c) => {
            c.territories.forEach(m => a.add(m.type));
            return a;
        });
        groups.territoryTypes = group((a, c) => {
            c.territories.forEach(m => a.add(m.subtype));
            return a;
        });
        groups.treasurechests = group((a, c) => {
            c.treasurechests.forEach(m => a.add(m.type));
            return a;
        });
        groups.mobtiers = group((a, c) => {
            c.mobcounts.forEach(m => a.add(m.tier));
            return a;
        });
        groups.resourcetypes = group((a, c) => {
            c.distribution.forEach(m => a.add(m.name));
            return a;
        });
        groups.resourcetiers = group((a, c) => {
            c.distribution.forEach(m => a.add(m.tier));
            return a;
        });
        groups.worldbosses = group((a, c) => {
            c.worldbosses.forEach(m => a.add(m.type));
            return a;
        });
        groups.dungeons = group( (a, c) => {
            c.dungeons.forEach(d => a.add(d))
            return a;
        })
        groups.chests = group( (a, c) => {
            c.chests.forEach(d => a.add(d))
            return a;
        })
        groups.ready = true;
        setGroups(groups);
        setWorld(world);
    }, [cidCookie]);

    const [options, setOptions] = React.useState({
        tiers: [],
        roads: "all",
        typeNames: [],
        timeregions: [],
        features: [],
        territories: [],
        territoryTypes: [],
        treasurechests: [],
        worldbosses: [],
        dungeons: [],
        chests: [],
    });

    const [result, setResult] = React.useState([]);
    const [cookies] = useCookies(['mode']);
    const [searchDirections, setSearchDirections] = React.useState("");

    function isDarkMode() {
        return cookies.mode === "dark";
    }

    React.useEffect(() => {
        if (groups.ready) {
            function check(option, value) {
                return option.length !== 0 ? option.includes(value) : true;
            }

            function checkContains(option, value) {
                return option.length !== 0 ? option.some(v => value.includes(v)) : true;
            }

            let filter = world
                .filter(c => check(options.tiers, c.tier))
                .filter(c => check(options.typeNames, c.type_name))
                .filter(c => check(options.timeregions, c.timeregion))
                .filter(c => checkContains(options.features, c.minimapmarkers.map(m => m.type)))
                .filter(c => checkContains(options.territories, c.territories.map(t => t.type)))
                .filter(c => checkContains(options.territoryTypes, c.territories.map(t => t.subtype)))
                .filter(c => checkContains(options.treasurechests, c.treasurechests.map(t => t.type)))
                .filter(c => checkContains(options.worldbosses, c.worldbosses.map(t => t.type)))
                .filter(c => checkContains(options.dungeons, c.dungeons))
                .filter(c => checkContains(options.chests, c.chests))
                .filter(c => options.roads === "roads" ? c.road : options.roads === "noroads" ? !c.road : true)
            ;
            setResult(filter);
            track("search", {...options, result: filter.length});
        }
    }, [options, groups, world, track]);

    function updateOptions(e, d, which) {
        const value = {};
        value[which] = d.value;
        setOptions({...options, ...value});
    }

    const SearchDropdown = (props) => {
        return <>
            <span>{props.label}</span>
            <Dropdown className="options" placeholder={props.placeholder} fluid multiple selection
                      options={groups[props.options]} value={options[props.options]}
                      onChange={(e, v) => updateOptions(e, v, props.options)}/>
        </>
    }

    React.useEffect(() => {
        if (result.length > 0 && result.length <= 20) {
            const base = "/?search=";
            const zones = result.map(cluster => cluster.displayname).reduce( (p, c, i) => p + c + (i === result.length - 1 ? "" : ","), "");
            const url = base + encodeURI(zones);
            setSearchDirections(url);
            console.log(url);
        }
    }, [result]);

    return <div>
        <h1><a href="/"><img src={isDarkMode() ? "roamapp-name-dark.png" : "roamapp-name.png"} height="90" alt="ROAMapp"
                 style={{margin: "20px"}}/></a></h1>
        <style type="text/css">
            {":root { background-color: #FFFFFF; }"}
            {isDarkMode() ? darkMode : ""}
        </style>
        <h1>Instant Zone Search</h1>
        {groups.ready ? <>
                <SearchDropdown label="Tier" placeholder="Choose zone tiers" options="tiers"/>
                <SearchDropdown label="Zone Type" placeholder="Choose zone types" options="typeNames"/>
                <span>Roads</span>
                <Dropdown className="options" placeholder="Restrict to roads or normal zones" fluid selection clearable
                          options={roadOptions} value={options.roads}
                          onChange={(e, v) => updateOptions(e, v, "roads")}/>

                <SearchDropdown label="Region" placeholder="Choose time regions" options="timeregions"/>
                <SearchDropdown label="Features" placeholder="Choose zone features" options="features"/>
                <SearchDropdown label="Territories & Castles" placeholder="Choose territory, castle or seigecamp" options="territories"/>
                <SearchDropdown label="Territory & Castle Types" placeholder="Choose the kind of territory or castle" options="territoryTypes"/>
                <SearchDropdown label="Treasure Chests" placeholder="Choose the kind of treasure it has" options="treasurechests"/>
                <SearchDropdown label="Worldbosses" placeholder="Choose the worldbosses" options="worldbosses"/>
                <SearchDropdown label="Road Dungeons" placeholder="Choose the dungeons" options="dungeons"/>
                <SearchDropdown label="Road Chests" placeholder="Choose the chests" options="chests"/>
            </>
            : ""}
        <h2>Zones: {result.length}</h2>
        <div>
            {result.map(cluster => {
                return <span key={cluster.id}>{cluster.displayname}<br/></span>
            })}
            {result.length < 20 ? <>
                <br/>
                <a href={searchDirections}>Directions</a>
            </> : ""}
        </div>
    </div>
}

export default Search;
