import React, {useContext} from "react";
import {useCookies} from "react-cookie";
import {setDefaultData, Tracking} from "./Tracking";
import {Button, Dropdown, Table} from "semantic-ui-react";
import {Slider} from "react-semantic-ui-range";
import ReactDOM from "react-dom";


const darkMode = "" +
    ":root { filter: invert();} " +
    "img {filter: invert();}";

function nFormatter(num, digits) {
    const si = [
        {value: 1, symbol: ""},
        {value: 1E3, symbol: "k"},
        {value: 1E6, symbol: "m"},
        {value: 1E9, symbol: "b"},
        {value: 1E12, symbol: "t"},
        {value: 1E15, symbol: "p"},
        {value: 1E18, symbol: "e"}
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break;
        }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}

const getValue = (prev, current) => prev + (current.value ? current.value * current.count / 10000 : 0);

const Inventory = () => {
    const [cookies] = useCookies(['mode']);
    const [cidCookie] = useCookies(['cid']);
    const [vaults, setVaults] = React.useState();
    const [reload, setReload] = React.useState(0);
    const [rowSize, setRowSize] = React.useState(8);
    const [imageSize, setImageSize] = React.useState(100);
    const [groups, setGroups] = React.useState({});
    const track = useContext(Tracking);
    const [options, setOptions] = React.useState({
        items: [],
        zones: [],
    });

    function updateOptions(e, d, which) {
        const value = {};
        value[which] = d.value;
        setOptions({...options, ...value});
    }

    const SearchDropdown = (props) => {
        return <>
            <span>{props.label}</span>
            <Dropdown className="options" placeholder={props.placeholder} fluid multiple selection search
                      options={groups[props.options]} value={options[props.options]}
                      onChange={(e, v) => updateOptions(e, v, props.options)}/>
        </>
    }

    React.useEffect(() => {
        let cid = cidCookie.cid;
        if (!cid) cid = "unknown";
        setDefaultData({cid: cid});
    }, [cidCookie]);

    function isDarkMode() {
        return cookies.mode === "dark";
    }

    function reloadVaults() {
        setVaults(null);
        setReload(reload + 1);
    }

    React.useEffect(() => {
        fetch(
            "https://chestupdater.albionroamapp.com/view?cid=" + cidCookie.cid
        ).then(response => response.json()).then(result => {
            const vaults = result.vaults;
            const items = vaults.flatMap(v => v.items);

            function group(reducer) {
                return Array.from(vaults.reduce(reducer, new Set()))
                    .filter(t => t)
                    .sort().map(t => {
                        return {key: t, text: t, value: t};
                    });
            }

            function groupItems(reducer) {
                return Array.from(items.reduce(reducer, new Set()))
                    .filter(t => t)
                    .sort().map(t => {
                        return {key: t, text: t, value: t};
                    });
            }

            const groups = {};
            groups.items = groupItems((a, c) => {
                if (c.item) {
                    a.add(c.item.name)
                }
                return a;
            });
            groups.zones = group((a, c) => {
                return a.add(c.zone);
            });
            groups.creators = groupItems((a, c) => {
                const creator = c.creator;
                if (creator) {
                    a.add(creator);
                }
                return a;
            })
            groups.ready = true;
            ReactDOM.unstable_batchedUpdates(() => {
                track("inventory", {
                    groups: groups.items.length,
                    vaults: vaults.length,
                });
                setGroups(groups);
                setVaults(vaults);
            });
        })
    }, [reload, cidCookie, track]);

    function removeChest(character, zone, vault) {
        const params = new URLSearchParams({
            cid: cidCookie.cid,
            character: character,
            zone: zone,
            vault: vault,
        });

        fetch(
            "https://chestupdater.albionroamapp.com/remove?" + params,
            {
                method: "POST"
            }
        ).then(response => response.json()).then(result => {
            setReload(reload + 1);
        });
    }

    function check(option, value) {
        return option ? option.length !== 0 ? option.includes(value) : true : true;
    }

    // eslint-disable-next-line no-unused-vars
    function checkContains(option, value) {
        return option.length !== 0 ? option.some(v => value.includes(v)) : true;
    }

    function filterItems(items) {
        return items
            .filter(i => check(options.items, i.item ? i.item.name : null))
            .filter(i => check(options.creators, i.creator))
            ;
    }

    function filterVaults(vaults) {
        return vaults.filter(v => check(options.zones, v.zone));
    }

    function makeItemRows(items) {
        const rows = [];
        if (groups.ready) {
            const fitleredItems = filterItems(items);
            for (let i = 0; i < fitleredItems.length; i += rowSize) {
                rows.push(fitleredItems.splice(i, rowSize));
            }
        }
        return rows;
    }

    function addPadding(count) {
        const str = "" + count;
        return str.length === 1;
    }

    const rowSizeSettings = {
        start: rowSize,
        min: 4,
        max: 20,
        step: 1,
        onChange: value => {
            setRowSize(value);
        }
    };

    const imageSizeSettings = {
        start: imageSize,
        min: 20,
        max: 200,
        step: 1,
        onChange: value => {
            setImageSize(value);
        }
    };
    let keycount = 0;

    return <div>
        <h1><a href="/"><img src={isDarkMode() ? "roamapp-name-dark.png" : "roamapp-name.png"} height="90" alt="ROAMapp"
                             style={{margin: "20px"}}/></a></h1>
        <style type="text/css">
            {":root { background-color: #FFFFFF; }"}
            {isDarkMode() ? darkMode : ""}
        </style>
        <h1>Inventory Manager</h1>
        <span>Row Size:</span><Slider value={rowSize} settings={rowSizeSettings}/>
        <span>Image Size:</span><Slider value={imageSize} settings={imageSizeSettings}/>
        <Button onClick={reloadVaults}>Reload</Button>
        <br/>
        <br/>
        {groups.ready ? <>
                <SearchDropdown label="Items" placeholder="Choose items" options="items"/>
                <SearchDropdown label="Zones" placeholder="Choose zones" options="zones"/>
                <br/>
            </>
            : ""}

        {vaults ?
            <h2>Total: {nFormatter(filterVaults(vaults).reduce((prev, current) => prev + filterItems(current.items).reduce(getValue, 0), 0), 1)}</h2> : ""}
        <div>{vaults ?
            filterVaults(vaults).map(vault => {
                const rows = makeItemRows(vault.items);
                if (rows.length === 0) return "";
                return <Table key={"vault" + (keycount++)} celled compact="very">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                colSpan={rowSize - 1}>Character: {vault.owner} Zone: {vault.zone} Location: {vault.name} Value: {
                                nFormatter(filterItems(vault.items).reduce(getValue, 0), 1)
                            }</Table.HeaderCell>
                            <Table.HeaderCell><Button
                                onClick={() => removeChest(vault.owner, vault.zone, vault.name)}>X</Button></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>{rows.map(row => {
                        return <Table.Row  key={"row" + (keycount++)}>
                            {row.map(item => {
                                const title = nFormatter(item.value / 10000, 1);
                                const url = "https://render.albiononline.com/v1/item/" + item.item.item_id +
                                    "?count=" + item.count + (item.quality ? "&quality=" + item.quality : "");
                                return <Table.Cell key={"item" + (keycount++)}>
                                    <img title={title} height={imageSize} width={imageSize} alt={item.item.name}
                                         src={url}/>
                                    <span
                                        className="count">{addPadding(item.count) ? <>&nbsp;&nbsp;</> : ""}{item.count}</span>
                                </Table.Cell>
                            })}
                        </Table.Row>
                    })}</Table.Body>
                </Table>
            })
            : "Loading..."}</div>

    </div>;

}

export default Inventory